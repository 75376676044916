import './App.css';
import "./racing.scss"
import {ethers} from "ethers"
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';

import "./fonts/RetroRace/HardRace.otf"

import turbopeperaceABI from "./abi/turbopeperace.json"
import tokenABI from "./abi/token.json"
import nftABI from "./abi/nft.json"

import logo from "./images/logo.gif"

import racemap from "./images/racetrack1.gif"
import racing from "./images/racemap.gif"

import howtoimg from "./images/howtoimage.gif"
import howtoimg2 from "./images/howtoimage2.gif"

import car1 from "./images/car1.gif"
import car2 from "./images/car2.gif"
import car3 from "./images/car3.gif"
import car4 from "./images/car4.gif"
import car5 from "./images/car5.gif"
import car6 from "./images/car6.gif"
import car7 from "./images/car7.gif"
import car8 from "./images/car8.gif"
import car9 from "./images/car9.gif"
import car10 from "./images/car10.gif"

import carside1 from "./images/carside1.gif"
import carside2 from "./images/carside2.gif"
import carside3 from "./images/carside3.gif"
import carside4 from "./images/carside4.gif"
import carside5 from "./images/carside5.gif"
import carside6 from "./images/carside6.gif"
import carside7 from "./images/carside7.gif"
import carside8 from "./images/carside8.gif"
import carside9 from "./images/carside9.gif"
import carside10 from "./images/carside10.gif"

import gold from "./images/gold.png"
import silver from "./images/silver.png"
import bronze from "./images/bronze.png"

import nftPlaceholder from "./images/sample1.png"

import tg from "./images/tg.png"
import tw from "./images/tw.png"
import os from "./images/os.png"
import web from "./images/webb.png"

import turbo from "./sounds/turbo.mp3"
import music from "./sounds/music.mp3"

//let tokenAddress = "0x73D3A884322AF11C6a5e35118c8296CBdAd68cAF"
let turbopeperaceAddress = "0x607C6d8c0c85afBE109a9723e67724DCc70D824F"
let nftAddress = "0xFbE4bF2A7e6f0d4053073135D1ffe60b3fcDdD63"
let jsonRPCprovider = process.env.REACT_APP_JSONRPCPROVIDER
let providerJSONRPC = new ethers.JsonRpcProvider(jsonRPCprovider)
let carGifs = [car1,car2,car3,car4,car5,car6,car7,car8,car9,car10]
let carSide = [carside1,carside2,carside3,carside4,carside5,carside6,carside7,carside8,carside9,carside10]
let prizes = [gold,silver,bronze]
let turboSound = new Audio(turbo)
turboSound.volume = 0.1
let musicSound = new Audio(music)
musicSound.loop = true
musicSound.volume = 0.25


function App() {
  useEffect(() =>{
    document.getElementById("TrackButton0").style.setProperty("background", "cyan")
  },[])
  
  let [trackID, setTrackID] = useState(0)

  async function handleTrackChange(id){
    setTrackID(id)
    document.getElementById(`TrackButton0`).style.setProperty("background", "none")
    document.getElementById(`TrackButton1`).style.setProperty("background", "none")
    document.getElementById(`TrackButton${id}`).style.setProperty("background", "cyan")
  }
  
  return (
    <div className="App">
      <Header/>
      <ToastContainer 
      position='top-center'
      autoClose={1000}
      theme="dark"
      newestOnTop={false}
      />
      <div id='TrackSelectorContainer'>
        <h2>Select Track</h2>
        <div id='TrackButtons'>
          <button id='TrackButton0' onClick={e=>handleTrackChange(0)}>VROOM</button>
          <button id='TrackButton1' onClick={e=>handleTrackChange(1)}>PEPE</button>
        </div>
      </div>
      {
        trackID == 0 && <RaceTrack trackID="0" trackName="Vroombürgring"/>
      }
      {
        trackID == 1 && <RaceTrack trackID="1" trackName="Pepe Carlo"/>
      }
      <InfoSection/>
      <Footer/>
    </div>
  );
}

function Header(){
  let [cwText, setcwText] = useState("Connect Wallet")
  
  async function connect(){
    if(typeof window.ethereum === 'undefined'){
      toast.error("Please install MetaMask or use MetaMask browser!")
    }else{
      let provider = new ethers.BrowserProvider(window.ethereum)
      let signer = await provider.getSigner()
      .then(res => {
        if(cwText === "Connect Wallet") {
          toast.success("Connected with 0x..."+res.address.slice(38,42))
        }
        setcwText("0x..."+res.address.slice(38,42))
        window.ethereum.on("accountsChanged", async () => {
          res = await provider.getSigner()
          setcwText("0x..."+res.address.slice(38,42))
        })
      })
      .catch((ex) => {
        toast.error(ex.message)})
      
    }
  }
  return(
    <div id='Header'>
      <div id='SiteID'>
        <h1>VROOM</h1>
        <img src={logo} alt="logo"/>
        <h1>RACING</h1>
      </div>
      <div id='HeaderRight'>
        <Socials/>
        
        <button onClick={connect}>{cwText}</button>
      </div>
    </div>
  )
}

function RaceTrack(props) {
  let [totalRacers, setTotalRacers] = useState(0)
  let [token, setToken] = useState("TOKEN")
  let [participationFee, setParticipationFee] = useState(0)
  let [maxParticipants, setMaxParticipants] = useState(0)
  let [winnerRate, setWinnerRate] = useState(0)
  let [racers, setRacers] = useState([])

  let [countdown, setCountdown] = useState(0)
  let countdownReal = 0;
  let [now, setNow] = useState(0)
  let timeNow = 0;
  let timerInterval;

  let randomInterval;
  let pRandom = [0,0,0,0,0,0,0,0,0,0]

  let [didRaceStart, setDidRaceStart] = useState(false)
  let [didRaceEnd, setDidRaceEnd] = useState(false)
    
  async function FinalizeRace(){
    setDidRaceEnd(true)
    let finishTimeout = setTimeout(() => {
      setDidRaceStart(false)
      clearInterval(randomInterval)
      timerInterval = setInterval(() => {
        timeNow = Number(Number(Date.now()/1000).toFixed())
        setNow(timeNow)
        if(countdownReal > 0) if(countdownReal - timeNow < 0) StartRace()
      },1000);
      clearTimeout(finishTimeout)
    }, 8000);
  }

  async function StartRace(){
    clearInterval(timerInterval)
    setDidRaceStart(true)
    let turbopeperaceContract = new ethers.Contract(turbopeperaceAddress,turbopeperaceABI,providerJSONRPC)
    turbopeperaceContract.once("PickWinner(uint256)",async () =>{
      await getTrackInfo()
      await FinalizeRace()
      console.log("Race has finished.")
     })
    for(let i = 0; i < 10; i++){
      pRandom[i] = 0
    }
    randomInterval = setInterval(() => {
      let random = Number(Number(Math.random()*-30).toFixed(0))
      let sRandom = []
      let rRandom = []
      for(let i = 0 ; i < 10 ; i++){
        sRandom[i] = Number(Number(Math.random()*30).toFixed(0))
        rRandom[i] = random + sRandom[i]
        pRandom[i] += rRandom[i]
        if(pRandom[i] > 100) pRandom[i] = 100
        document.documentElement.style.setProperty(`--r${i+1}`,pRandom[i] + "px")
      }
    }, 100);
  }
 
  let [winners, setWinners] = useState([])
  let [NFTs, setNFTs] = useState([nftPlaceholder,nftPlaceholder,nftPlaceholder,nftPlaceholder,nftPlaceholder,nftPlaceholder,nftPlaceholder,nftPlaceholder,nftPlaceholder,nftPlaceholder])

  useEffect(() => {
    timerInterval = setInterval(() => {
      timeNow = Number(Number(Date.now()/1000).toFixed())
      setNow(timeNow)
      if(countdownReal > 0) if(countdownReal - timeNow < 0) StartRace()
    },1000);

    async function gatherInfo(){
      let turbopeperaceContract = new ethers.Contract(turbopeperaceAddress,turbopeperaceABI,providerJSONRPC)
      let trackInfo = await turbopeperaceContract.raceTracks(props.trackID)
      let tokenContract = new ethers.Contract(trackInfo[4],tokenABI,providerJSONRPC)
      let tokenName = await tokenContract.symbol()      
      setToken(tokenName)
      setParticipationFee(ethers.formatEther(trackInfo[6]))
      setWinnerRate(ethers.formatUnits(trackInfo[0],0))
      setMaxParticipants(ethers.formatUnits(trackInfo[2],0))

      turbopeperaceContract.on("ParticipateRace(uint256)",async () => await getTrackInfo())
      turbopeperaceContract.on("StopRace(uint256)",async () => await getTrackInfo())
    }
      
    gatherInfo()
    getTrackInfo()
  },[])

  async function joinGame(){
    turboSound.play()
    let provider = new ethers.BrowserProvider(window.ethereum)
    let signer = await provider.getSigner()
    let nftContract = new ethers.Contract(nftAddress,nftABI,signer)
    let joinerNFT = await nftContract.tokensOfOwner(signer)
    if(joinerNFT.length === 0){
      toast.error(<a style={{textDecoration:"underline", color:"white"}} href="https://tpaic.turbopepe.net/">You don't have any TPAIC NFT! Please buy one to join the race!</a>)
      return;
    }
    let turbopeperaceContract = new ethers.Contract(turbopeperaceAddress,turbopeperaceABI,signer)
    let trackInfo = await turbopeperaceContract.raceTracks(props.trackID)
    let tokenContract = new ethers.Contract(trackInfo[4],tokenABI,signer)
    let allowanceBN = await tokenContract.allowance(signer.address,turbopeperaceAddress)
    let joinGameToast = toast("Joining Game...")
    if(allowanceBN < trackInfo[6]){
      //increase allowance
      toast.update(joinGameToast, {render:`Approving ${token}`,type:"info",isLoading:"true"})
      await tokenContract.approve(turbopeperaceAddress,trackInfo[6])
      .then(async res => {
        await res.wait()
        toast.success(`Approved ${token}`)
      })
      .catch(ex => {
        toast.dismiss(joinGameToast)
        return(toast.error(ex.reason))
      })
    }
    toast.update(joinGameToast, {render:`Joining Race: ${props.trackName}`,type:"info",isLoading:"true"})
    await turbopeperaceContract.participateRace(props.trackID)
    .then(async res => {
      await res.wait()
      toast.dismiss(joinGameToast)
      toast.success(`Joined Race: ${props.trackName}`)
    })
    .catch(ex => {
      toast.dismiss(joinGameToast)
      return(toast.error(ex.reason))
    })
  }

  async function getTrackInfo(){
    let provider = new ethers.JsonRpcProvider(jsonRPCprovider)
    let turbopeperaceContract = new ethers.Contract(turbopeperaceAddress,turbopeperaceABI,provider)
    
    let currentRacers = await turbopeperaceContract.showRacers(props.trackID)
    let nftArray = []
    setTotalRacers(currentRacers.length)
    setRacers(currentRacers)
    for(let i = 0; i < currentRacers.length;i++){
      nftArray.push(await getNFT(currentRacers[i]))
    }
    setNFTs(nftArray)

    await turbopeperaceContract.showWinners(props.trackID)
    .then(res => setWinners(res))
    .catch(ex => console.log(ex))

    let trackInfo = await turbopeperaceContract.raceTracks(props.trackID)
    countdownReal = Number(ethers.formatUnits(trackInfo[3],0))
    setCountdown(countdownReal)
    if(currentRacers.length >= ethers.formatUnits(trackInfo[2],0)){
      StartRace()
    }
  }

  async function getNFT(address){
    let provider = new ethers.JsonRpcProvider(jsonRPCprovider)
    let nftContract = new ethers.Contract(nftAddress,nftABI,provider)
    let racerNFTs = await nftContract.tokensOfOwner(address)
    if(racerNFTs.length === 0) return nftPlaceholder
    else{
      let racerNFT = ethers.formatUnits(racerNFTs[0],0)
      let imgURL = `https://nftimg.turbopepe.net/${racerNFT}.png`
      return imgURL
    }
  }

  function RaceAnimation(){
    return(
      <div id='RaceAnimation'>
        {
          !didRaceEnd ? 
          <div id='RaceTable'>
          <div id='RaceAnimHead'>{props.trackName}</div>
          <div id='RacerListCell'>
          {
            racers.map((value,index) => {
              return (
              <div id='RacerList' key={index}>
                <img src={NFTs[index]} loading='lazy' key={index+3} alt={"NFT"}/>
                <span key={index+2}>{index+1+". 0x.."+value.slice(38,42)}</span>
              </div>
              )
            })            
          }
          </div>          
          </div>
          :
          <div id='RaceTable'>
            <div id='RaceAnimHead'>{props.trackName} Winners</div>
            <div id='RaceAnimWinnerList'>
              {
                winners.map((value,index) => {
                  return(
                    <div id='RaceAnimWinnerCell' key={index+2}>
                      <img src={prizes[index]} alt="Winner" key={index} loading='lazy'/>
                      <span key={index+1}>{index+1}. 0x...{value.slice(38,42)}</span>
                    </div>
                  )
                })
              }
            </div>          
          </div>
        }
        
        {
          !didRaceEnd ?
          <div id='RaceAnimationTrack'>
          {
            racers.map((value,index) => {
              return (
              <div id='RaceAnimList' key={index}>
                <img id='RaceAnimPP' src={NFTs[index]} loading='lazy' key={index+3} alt={"NFT"}/>
                <img id='RaceAnimCar' src={carSide[index]} loading='lazy' key={index+1} alt="CarSide"/>
              </div>
              )
            })
          }
          </div>
          :
          <></>
        }
        
      </div>
    )
  }

  return didRaceStart ? (
    <RaceAnimation/>
  ) : (
      <div id='RaceTrack'>
      <div id='RaceTrackTitle'>
        <h1>Track: {props.trackName}</h1>
        <h2>Racers Ready: {totalRacers}/{maxParticipants}</h2>
        {
          countdown === 0 ? 
          <h2>Waiting for {maxParticipants/2 - totalRacers} more players.</h2>
          :
          totalRacers >= maxParticipants ?
          <h2 style={{color:"yellow"}}>Race has started!</h2>
          :
          Math.floor(countdown-now) < 0 ?
          <h2 style={{color:"yellow"}}>Race has started!</h2>
          :
          <h2>Race starts in: {Math.floor(countdown-now)+"s"}</h2>
        }
        <div id='TitleButtons'>
          <button onClick={() => window.open("https://tpaic.turbopepe.net/")}>Mint TPAIC</button>
          <button onClick={() => window.open("https://opensea.io/collection/tpaic")}>Buy TPAIC</button>
        </div>
      </div>
      <div id='RaceTrackCell'>
        <div id='RacerListCell'>
        {
          racers.map((value,index) => {
            return (
            <div id='RacerList' key={index}>
              <img src={NFTs[index]} loading='lazy' key={index+3} alt={"NFT"}/>
              <span key={index+2}>{index+1+". 0x.."+value.slice(38,42)}</span>
              <img src={carGifs[index]} loading='lazy' key={index+1} alt="CarGif"/>
            </div>
            )
          })
        }
        </div>
        <div id='RaceTrackRight'>
          {
              countdown === 0 ? 
              <img src={racemap} alt="racemap"/>
              :
              totalRacers >= maxParticipants ?
              <img src={racing} alt="racing"/>
              :
              Math.floor(countdown-now) < 0 ?
              <img src={racing} alt="racing"/>
              :
              <img src={racemap} alt="racemap"/>
          }
          
          <button onClick={() => joinGame(props.trackID)}>Join Race</button>
          <div id='RaceTrackInfo'>
            <div id='InfoCell'>
              <span>Token:{token}</span>
              <span>Fee:{Number(participationFee).toLocaleString()}{" "+token}</span>
            </div>
            <div id='InfoCell'>
              <span style={{textDecoration:"underline", textDecorationColor:"cyan"}}>Prizes</span>
              <span>1st:{Number((totalRacers*participationFee*winnerRate)/100).toLocaleString()} {" "+token}</span>
              <span>2nd:{Number((totalRacers*participationFee*winnerRate)/400).toLocaleString()} {" "+token}</span>
              <span>3rd:{Number((totalRacers*participationFee*winnerRate)/800).toLocaleString()} {" "+token}</span>
            </div>
            <div id='InfoCell'>
              <span style={{textDecoration:"underline", textDecorationColor:"cyan"}}>Game Winners</span>
              {
                winners.map((value,index) => {
                  return(
                    <span key={index}>{index+1}. 0x...{value.slice(38,42)}</span>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      </div>
  )
}

function InfoSection(){
  return(
    <div id='Information'>
      <h1>How Does It Work?</h1>
      <div id='InformationCell'>
        <img src={howtoimg} alt='howtoimg' loading='lazy'/>
        <div id='InfoCellRight'>
          <h2>Game Mechanics</h2>
          <span>Each race needs a minimum participant count of 5 racers to start.</span>
          <span>We have capped the maximum participant count to 10 per race.</span>
          <span>Once minimum participants are reached, a countdown timer of 3 minutes will be activated before race starts.</span>
          <span>Alternatively, once maximum participants are reached, it will start immediately.</span>
          <span>To join the game, you must contribute the basic "Fee" on the corresponding track card.</span>
          <span>Each race duration lasts 20~ seconds. 3 winners will be presented at the end of the race.</span>
          <span>Winners are selected through a blockchain random number generator, which is verifyable via code.</span>
          <span>The 1st place winner receives 50% of the pool. 2nd place wins 12,5% of the pool. 3rd place wins 6,25% of the pool.</span>
          <span>The remaining portion of the pool will be sent to the Racing Treasury to cover gas fees.</span>
          <span>A racer can only join one race track per session. To participate any race, you must have a TPAIC NFT in your wallet.</span>
        </div>
      </div>
      <div id='InformationCell'>
        <div id='InfoCellLeft'>
          <h2>Disclaimer</h2>
          <span>Since this game has only 3 winners, remaining participants will be losing their tokens.</span>
          <span>Therefore, play responsibly, TurboPepe $VROOM team can not be held responsible for any losses.</span>
          <span>If you have any doubts or don't have enough knowledge about tokenized Web3 gaming, please consult to an expert.</span>
          <span>TurboPepe team holds the right to cancel a race on a track whenever needed, refunding 75% of the participants' tokens.</span>
          <span>TurboPepe team does not have any relationship with the tokens used in the racing game, unless said otherwise.</span>
          <span>If you see anything unusual or to be fixed in the dApp, please inform us through TurboPepe socials.</span>
        </div>
        <img src={howtoimg2} alt='howtoimg2' loading='lazy'/>
      </div>
    </div>
  )
}

function Socials(){
  let [musicPaused, setMusicPaused] = useState(true)
  let icon1 = <svg style={{width:"15px", fill:"white"}} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.414" clipRule="evenodd" viewBox="0 0 288 288" id="sound-on"><path d="M65.191 186.95H26.988v-85.901h38.203l85.959-62.04V248.99l-85.959-62.04ZM221.975 239.471l-24.22-24.548c37.903-38.417 37.301-101.313-1.346-140.482l25.567-25.913c52.023 52.727 52.023 138.216-.001 190.943Zm-36.331-154.12c32.701 33.144 33.303 86.27 1.345 118.66l-21.532-21.823c20.81-21.091 20.81-55.286 0-76.377l20.187-20.46Z"></path></svg>
  let icon2 = <svg style={{width:"15px", fill:"white"}} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.414" clipRule="evenodd" viewBox="0 0 288 288" id="sound-off"><path d="M32.288 52.93 54.93 30.288l203.782 203.781-22.642 22.643L32.288 52.93ZM56.764 100.049l94.386 94.386v53.555l-85.959-62.04H26.988v-85.901h29.776Zm164.68 74.11c10.449-34.16 2.051-73.267-25.035-100.718l25.566-25.913c40.923 41.477 49.655 103.225 26.187 153.35l-26.718-26.719Zm-41.331-41.331c-1.927-10.26-6.814-20.069-14.656-28.017l20.187-20.46c20.864 21.147 28.661 50.428 23.25 77.258l-28.781-28.781Zm-28.963-28.963L112.9 65.616l38.25-27.607v65.856Z"></path></svg>
  function handleMusic(){
    if(musicPaused){
      musicSound.play()
      setMusicPaused(false)
    }
    else{
      musicSound.pause()
      setMusicPaused(true)
    }
  }
  return(
    <div id='Socials'>
      <a href='https://opensea.io/collection/tpaic'><img src={os} alt='social'/></a>
      <a href='https://turbopepe.net'><img src={web} alt='social'/></a>
      <a href='https://twitter.com/TurboPepeAIC'><img src={tw} alt='social'/></a>
      <a href='https://t.me/turbopepeofficial'><img src={tg} alt='social'/></a>
      <button onClick={() => handleMusic()}>{!musicPaused ? icon1 : icon2}</button>
    </div>
  )
}

function Footer(){
 return( 
 <div id='Footer'>
    <h3>TurboPepe $VROOM - 2023</h3>
    <h3>Version: v3.1</h3>
    <Socials/>
  </div>
  )
}



export default App;
